import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Grid } from "@material-ui/core"
import Box from "@material-ui/core/Box"

import MainHead from "../components/head"
import Header from "../components/Header_normal"
import Footer from "../components/footer"
import WrapTypo from "../components/WrapTypo"
import { ContactForm } from "../components/ContactForm"

const Contacts = () => {
  const data = useStaticQuery(graphql`
    query {
      contactImage: file(relativePath: { eq: "addrustContactImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <MainHead />
      <Box width="100%" bgcolor="#242424">
        <Header />

        <Box width="100%" maxWidth="900px" margin="auto">
          <Img
            style={{ width: "100%" }}
            fluid={data.contactImage.childImageSharp.fluid}
          />
        </Box>
        <Box mt={-3}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Box>
              <WrapTypo fs="30px" color="white">
                お問い合わせ
              </WrapTypo>
            </Box>
            <Box>
              <WrapTypo fs="16px" color="#9E9E9E">
                *=必須事項
              </WrapTypo>
            </Box>
          </Grid>
        </Box>
        <Box width="100%" maxWidth="900px" margin="auto" padding="14% 0">
          <ContactForm />
        </Box>
        <Footer />
      </Box>
    </>
  )
}

export default Contacts
