// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contacts-result-js": () => import("./../../../src/pages/contactsResult.js" /* webpackChunkName: "component---src-pages-contacts-result-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-copy-js": () => import("./../../../src/pages/profile copy.js" /* webpackChunkName: "component---src-pages-profile-copy-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */)
}

