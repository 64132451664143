import React from "react"
import Img from "gatsby-image"
import "./index.css"
import "../style/font-style.css"
import Box from "@material-ui/core/Box"
import AOS from "aos"
import "aos/dist/aos.css"
import Head from "../components/head"
import Header from "../components/Header_normal"
import Footer from "../components/footer"
import { useStaticQuery, graphql, Link } from "gatsby"
import WrapTypo from "../components/WrapTypo"
import Grid from "@material-ui/core/Grid"

import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

{
  /* <h1
  style={{
    background:
      "linear-gradient( to right,  rgba(69,179,224,1) 25%, rgba(0,51,153,1) 75% )",
    WebkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
  }}
>
  fasfasd
        </h1> */
}
export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    })
  }
  const data = useStaticQuery(graphql`
    query {
      fv_all: file(relativePath: { eq: "fv_all.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      anj: file(relativePath: { eq: "anjusImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pc_anj: file(relativePath: { eq: "pc_anju.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_anj: file(relativePath: { eq: "sp_anju.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Head />
      <Box width="100%" bgcolor="#E5E5E5">
        <Header />

        <Box width="90%" maxWidth="1200px" m={"auto"} mt="50px">
          <Box>
            <GridResponsiveDirection>
              <Grid item xs={12} sm={6}>
                <WrapTypo
                  variant="h1"
                  fs="63px"
                  mt="32px"
                  xsfs="30px"
                  mdfs="40px"
                  color="#474747"
                >
                  石山　アンジュ
                </WrapTypo>
                <WrapTypo fs="16px" lh="24px" ls="10%">
                  Anju　Ishiyama
                </WrapTypo>
                <WrapTypo mt="32px" fs="18px" lh="200%" Yumincho xsfs="14px">
                  シェアリングエコノミー活動家
                  <br />
                  一般社団法人Public Meets Innovation代表
                  <br />
                  内閣官房シェアリングエコノミー伝道師
                  <br />
                  一般社団法人シェアリングエコノミー協会事務局長
                </WrapTypo>
                <WrapTypo
                  Notosans
                  fs="14px"
                  lh="180%"
                  mt="32px"
                  xsfs="14px"
                  style={{ textAlign: "justify" }}
                  mb="64px"
                >
                  「シェア(共有)」の概念に親しみながら育つ。2012年国際基督教大学(ICU)卒。新卒で(株)リクルート入社、その後(株)クラウドワークス経営企画室を経て現職。
                  シェアリングエコノミーを通じた新しいライフスタイルを提案する活動を行うほか、政府と民間のパイプ役として規制緩和や政策推進にも従事。総務省地域情報化アドバイザー、厚生労働省「シェアリングエコノミーが雇用・労働に与える影響に関する研究会」構成委員、経済産業省「シェアリングエコノミーにおける経済活動の統計調査による把握に関する研究会」委員なども務める。2018年米国メディア「Shareable」にて世界のスーパーシェアラー日本代表に選出。ほか
                  NewsPicks「WEEKLY OCHIAI」レギュラーMC、拡張家族Cift
                  メンバーなど、幅広く活動。著書「シェアライフ-新しい社会の新しい生き方（クロスメディア・パブリッシング）」がある。
                </WrapTypo>
              </Grid>
              <Grid item xs={12} sm={5} style={{ width: "100%" }}>
                <Box>
                  <Img
                    style={{ width: "100%", margin: "auto" }}
                    fluid={data.pc_anj.childImageSharp.fluid}
                  />
                </Box>
              </Grid>
            </GridResponsiveDirection>
            <Grid container justify="space-between">
              <Grid item xs={12} sm={5}>
                <Img
                  style={{ width: "100%", margin: "auto" }}
                  fluid={data.pc_anj.childImageSharp.fluid}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WrapTypo
                  variant="h1"
                  fs="63px"
                  mt="32px"
                  xsfs="30px"
                  mdfs="40px"
                  color="#474747"
                >
                  Anju　Ishiyama
                </WrapTypo>

                <WrapTypo
                  Notosans
                  fs="16px"
                  mt="32px"
                  xsfs="14px"
                  style={{ textAlign: "justify" }}
                  mb="64px"
                >
                  Founder of Japan Association of Public Meets Innovation
                  Secretariat of the Sharing Economy Association Japan Sharing
                  Economy Evangelist, Cabinet Secretariat, Government of Japan
                </WrapTypo>
                <WrapTypo
                  fs="14px"
                  lh="150%"
                  mt="32px"
                  xsfs="12px"
                  style={{ textAlign: "justify" }}
                  mb="200px"
                >
                  Anju Ishiyama is an social activist. Born in 1989. As an
                  expert of Sharing economy in Japan, She is engaged in
                  spreading the sharing economy including regulatory reform and
                  policy making. Author of “SHARE LIFE - Dive into Sharing
                  Economy (Cross Media Publishing)". She is also a Founder of
                  Public Meets Innovation Japan which is a Think tank for the
                  Millennial generation leaders since 2018. Member of the World
                  Economic Forum Global Future Council Japan.
                </WrapTypo>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  )
}

const GridResponsiveDirection = ({ children }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Grid
      container
      direction={isSmall ? "column-reverse" : "row"}
      justify="space-between"
    >
      {children}
    </Grid>
  )
}
