import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "../components/WrapTypo"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"

const Profile = () => {
  const data = useStaticQuery(graphql`
    query {
      anj: file(relativePath: { eq: "catchcopy.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Box width="90%" maxWidth="1200px" m={"auto"} mt={[6, 10, 20, 20, 20]}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12} sm={6} style={{ marginTop: "10%" }}>
            <Img
              style={{ width: "100%", margin: "auto" }}
              fluid={data.anj.childImageSharp.fluid}
            />
          </Grid>
          <Grid item xs={12} sm={5} style={{ marginTop: "10%" }}>
            <Box data-aos="fade-in" data-aos-duration="1000">
              <WrapTypo
                fs="22px"
                xsfs="15px"
                xsta="center"
                lh="350%"
                ls="5%"
                color="#fff"
                fw="700"
                style={{ margin: "auto" }}
              >
                人材の価値は、肩書ではない。
                <br />
                大切なことは、どれだけ夢中になれるかだ。
                <br />
                喜びや悔しさを楽しみながら、 <br /> 仕事に、仲間に夢中になれる。
                <br />
                そんな人にこそ価値がある。 <br />
                それが例え学生であっても。
                <br />
                一人ひとりの夢中を広げ、社会を変えていく。
                <br />
              </WrapTypo>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Profile
