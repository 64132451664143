import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Grid, styled } from "@material-ui/core"
import Box from "@material-ui/core/Box"

import MainHead from "../components/head"
import Header from "../components/Header_normal"
import Footer from "../components/footer"
import WrapTypo from "../components/WrapTypo"
import { ContactForm } from "../components/ContactForm"

const array1 = [
  {
    title: "情報セキュリティ管理規則の策定及び継続的改善",
    content:
      "当社は、情報セキュリティの取り組みを、経営並びに事業における重要課題のひとつと認識し、法令及びその他の規範に準拠・適合した情報セキュリティ管理規則を策定する。更に、当社役員を中心とした全社における情報セキュリティ管理体制を確立し、これを着実に実施する。加えて組織的、人的、物理的及び技術的な情報セキュリティを維持し、継続的に改善していく。	",
  },
  {
    title: "情報資産の保護と継続的管理",
    content:
      "当社は、当社の扱う情報資産の機密性、完全性及び可用性に対する脅威から情報資産を適切に保護するため、安全な管理策を講じる。また、事業継続のために、適切な管理措置を講じる。	",
  },
  {
    title: "法令・規範の遵守",
    content:
      "当社は、情報セキュリティに関する法令及びその他の規範を遵守する。また、当社の情報セキュリティ管理規則を、これらの法令及びその他の規範に適合させる。また、これらに違反した場合には、社員就業規則等に照らして、然るべき処分を行う。",
  },
  {
    title: "情報セキュリティ教育",
    content:
      "当社は、当社役員及び従業員へ情報セキュリティの意識向上を図るとともに、情報セキュリティに関する教育・訓練を行う。",
  },
  {
    title: "事故発生予防と発生時の対応	",
    content:
      "当社は、情報セキュリティ事故の防止に努めるとともに、万一、事故が発生した場合には、再発防止策を含む適切な対策を速やかに講じる。",
  },
  {
    title: "法令等の遵守",
    content:
      "当社は、全ての役職員が情報セキュリティに関する各種コンプライアンスに則り業務を実施する上で遵守すべき法令、規制および業界ガイドライン等を遵守する。",
  },
]

const array2 = [
  {
    title:
      "個人情報保護に関連する規則の策定および個人情報保護マネジメントシステムの継続的改善",
    content:
      "当社は、役員及び従業員に個人情報保護の重要性を認識させ、個人情報を適切に利用し、保護するための管理規則を策定し、個人情報保護マネジメントシステムを着実に実施します。更に、維持し、継続的に改善します。	",
  },
  {
    title: "個人情報の取得・利用・提供および目的外利用の禁止",
    content:
      "当社は、個人情報および特定個人情報（以下、個人情報）を取得する場合には、その目的、利用範囲を明示し、事前にご本人の同意を得て適切に取得します。また、当社が取得した個人情報、お客様から受託した個人情報はすべて同意を得た目的・利用範囲内でのみ適切に利用、提供、保管、委託管理を行い、利用目的終了後は適切に廃棄または返却を行います。",
  },
  {
    title: "安全対策の実施および是正",
    content:
      "当社は、個人情報の正確性および安全性を確保するため、情報セキュリティ対策をはじめとする安全対策を実施し、個人情報の漏えい、滅失またはき損の防止に努めます。また、安全対策上の問題が確認された場合など、その原因を特定し、是正措置を講じます",
  },
  {
    title: "法令・指針・規範の遵守",
    content:
      "当社は、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守します。また、当社の個人情報保護に関連する規則を、これらの法令および指針その他の規範に適合させます。",
  },
]

const array3 = [
  {
    title: "当社が取り扱う個人情報の利用目的（保護法第18条1項、JIS3.4.2.5項）",
    content:
      "当社では以下の「個人情報の利用目的特定表」の「当社の業務内容」を遂行するために、「利用目的」の項に記載する利用目的で、個人情報を取得し利用いたします。",
  },
  {
    title:
      "当社が取り扱う保有個人データ（JISでは開示対象個人情報）に関する事項（保護法第24条1項関連、JIS3.4.4.3関連",
    content: "",
  },
  {
    title:
      "当社が取り扱う個人データの第三者提供に関する事項（保護法第23条2項関連）",
    content:
      "当社では、当社にご提供いただきました個人情報は、当社社内でのみ利用し、第三者に開示することはいたしません。ただし、次のいずれかに該当する場合には、その限りではありません",
  },
  {
    title: "個人情報の取り扱いの委託",
    content:
      "当社は、ご提供いただいた個人情報について、利用目的を遂行するために外部に委託することがあります。",
    content2:
      "委託先については、情報セキュリティおよび個人情報保護に関する管理水準を評価し、必要な安全対策を実施している企業を選定し、適切に管理・監督を行ないます。",
  },
  {
    title: "個人情報提供の任意性	",
    content:
      "個人情報の当社への提供は任意でございますが、ご提供いただけなかった場合には、サービス等をご利用できない場合がございます。あらかじめご了解下さい。",
  },
  {
    title: "クッキー（Cookie）について",
    content:
      "当ウェブサイトはクッキー（Cookie）を使用しています。当サイトを利用される方が始めてご覧になる際に、クッキーはブラウザを独自・任意の数字によって識別します。クッキーの使用により個人情報を識別することはありません。クッキーの使用は、当ウェブサイトご利用の皆様のコンテンツ閲覧の全体的傾向の把握・分析のみに利用させていただき、それ以外の目的で利用することはございません。",
  },
]

const array4 = [
  {
    tittle:
      "上記「当社の業務内容」に記載された当社の業務、お取引先様とのお取引を実施するため",
    content: "",
  },
  {
    tittle:
      "当社が取り扱う製品・サービス・ソリューション等の情報提供（ダイレクトメールの発送、セミナー・イベント等のご案内）",
    content:
      "当社とのお取引に伴い当社が保有することとなった個人情報のご本人に対して、個別に事前にご了解を得た上で、および当社のWebサイトより情報提供に関するお申し込みをされたご本人に対して、当社が取り扱う製品・サービス・ソリューション等の情報提供を目的としてダイレクトメールの発送やメールマガジンの発行を行ないます。",
  },
  {
    tittle:
      "当社が主催・共催・後援するセミナー・イベント等への参加申込者に対するご連絡、受講事務手続き、およびセミナー・イベント実施結果の分析・統計、事後の改善のため",
    content: "",
  },
  {
    tittle:
      "他の業者から業務の委託または個人情報および特定個人情報の処理の委託を受けた場合、当該受託業務を適切に遂行するため",
    content: "",
  },
  {
    tittle:
      "お取引先様からのお問い合わせやご意見に関するご対応や、ご請求いただいた資料等の送付のため	",
    content: "",
  },
  {
    tittle:
      "報道関係者、各種ステークホルダー向けの講演会や説明会の案内状の送付および各種ご連絡のため",
    content: "",
  },
  {
    tittle: "当社の株主に対する各種案内状の送付、ご連絡、諸事務手続きのため",
    content: "",
  },
  {
    tittle:
      "ご本人確認のための検討、調査、対応およびご連絡、諸事務手続きのため",
    content: "",
  },
  {
    tittle:
      "セキュリティ確保のための外来者・来訪者および来訪目的等を確認し管理するため",
    content: "",
  },
  {
    tittle:
      "当社の採用活動に関して、円滑な手続きを行うためのご連絡やご対応、資料送付等のため",
    content: "",
  },
  { tittle: "業務上の緊急連絡のため", content: "" },
  { tittle: "各種支払調書作成のため", content: "" },
  {
    tittle:
      "その他通常の会社運営を行なうにあたり、物品の購入git、作業の受委託、金融取引等を実施するため	",
    content: "",
  },
]

const array5 = [
  { title: "見積書・納品書等の送付先、事務連絡先" },
  { title: "契約の締結" },
  { title: "ライセンス供与、ユーザ登録" },
  { title: "受託システム開発、システムの運用管理業務の受託等の受託業務の実施" },
  { title: "保守契約に基づく保守サポートサービス業務の実施" },
  { title: "債権債務および契約義務の履行" },
  {
    title:
      "新製品情報、契約期限切れご案内、パッチリリース情報提供等のお取引の実施に伴うアフターサービスの実施",
  },
  { title: "事業活動のご案内" },
  { title: "研究・開発・改善のためのアンケート調査（連絡、実施、分析、報告）" },
  { title: "契約先、納入先、請求先、保守連絡先等の把握および連絡網の整備" },
  { title: "その他、お取引に伴う業務上の諸ご連絡、資料、挨拶状の送付等のため" },
]

const array6 = [
  { title: "法令に基づき必要とされる手続きを行うため" },
  { title: "当社採用基準の充足を確認するため" },
  { title: "採用選考において本人の適性・能力を判断するため" },
  { title: "入社までの過程において緊急時の連絡をとるため" },
  { title: "配置計画を目的とした健康状態の確認をするため" },
]

const array7 = [
  { title: "当該個人情報取扱事業者の名称および個人情報保護管理者" },
  { title: "保有個人データの利用目的" },
  { title: "保有個人データの開示および訂正・追加または削除についての手続き" },
]
const array8 = [
  { title: "ご本人の同意がある場合（特定個人情報を除く）" },
  {
    title:
      "当社と機密保持契約を締結している協力企業等、業務委託契約を締結している業務委託会社、および当社の子会社に対して、お取引先様に明示した取得目的を実施するために個人情報を開示する必要が有る場合（例：納入先として配送業者に配送を委託する場合）",
  },
  {
    title:
      "ライセンス発行元、製品供給元等と当社とのライセンス契約や製品保守契約等のユーザ登録・報告等の契約義務履行のために、ライセンス発行元・製品供給元等への報告・提供が必要な場合",
  },
  { title: "統計的なデータとして個人を識別できない状態に加工した場合" },
  { title: "法令等に基づき、提供に応じなければならない場合" },
]
const Contacts = () => {
  return (
    <>
      <MainHead />
      <Box width="100%" bgcolor="#242424">
        <Header />
        <Box width="80%" margin="10% auto" maxWidth="850px">
          <Box marginBottom="10%">
            <Box
              textAlign="center"
              border="solid 1px #fff"
              borderRadius="3px"
              mb={3}
            >
              <Box py={2}>
                <WrapTypo fs="24px" fw="700" color="#fff">
                  情報セキュリティ基本方針
                </WrapTypo>
              </Box>
            </Box>

            <Box mb={3}>
              <WrapTypo fs="14px" color="#fff">
                株式会社アッドラスト（下記、当社といいます。）は、お客さまに安全で安心な品質のサービスを提供し、お客さまとの高い信頼関係を維持・継続していくことが重要です。
                <br />
                そのためには、当社が提供するサービスを通して知りえたお客さまの情報及び、当社が保有する情報システム・情報資産を、不正アクセス、犯罪、過失、災害などの様々な脅威から保護する為、必要な経営資源を投入し、物理的、技術的なセキュリティを強化していくことが必要です。さらに、従業員全員がセキュリティに対する高い意識をもって行動することで、お客さまとの信頼関係を維持し、当社の事業目的の完遂・発展に尽力していかなければなりません。
                <br />
                ここに、以下に掲げる情報セキュリティ方針を定め、全社一丸となって推進します。
              </WrapTypo>
            </Box>

            <Box width="91%" margin="auto">
              <ArrayView array={array1} />
            </Box>
          </Box>

          <Box marginBottom="10%">
            <Box
              textAlign="center"
              border="solid 1px #fff"
              borderRadius="3px"
              mb={3}
            >
              <Box py={2}>
                <WrapTypo fs="24px" fw="700" color="#fff">
                  個人情報保護方針
                </WrapTypo>
              </Box>
            </Box>

            <Box mb={3}>
              <WrapTypo fs="14px" color="#fff">
                株式会社アッドラスト（以下、当社といいます。）は、個人情報の保護を企業活動のひとつとして捉え、お客さまからお預かりする情報をはじめ、個人情報の適切な取り扱いならびに、安全管理に取り組むことが重要な社会的責任
                であると認識しています。
                <br />
                この責任を果たして行くために個人情報保護方針を定め、当社の役員および従業員は、この方針に従って、個人情報の適切な取り扱い・管理・
                維持に努めてまいります。
              </WrapTypo>
            </Box>

            <Box width="91%" margin="auto">
              <ArrayView array={array2} />
            </Box>
          </Box>

          <Box>
            <Box textAlign="center" border="solid 1px #fff" borderRadius="3px">
              <Box py={2}>
                <WrapTypo fs="24px" fw="700" color="#fff">
                  個人情報の取り扱い
                </WrapTypo>
                <WrapTypo fs="14px" color="#fff">
                  個人情報保護法および
                  <br />
                  JISQ15001:2006に基づく公表事項
                </WrapTypo>
              </Box>
            </Box>

            <Box mt={2}>
              <WrapTypo fs="14px" color="#fff">
                個人情報の保護に関する法律（以下、保護法という）および、JISQ15001:2006（以下、JIS）に基づき、公表または本人が容易に知り得る状態に置くべきものと定められている事項を、以下に掲載します。
              </WrapTypo>
            </Box>

            <Box>
              <ArrayView1 array={array3} />
            </Box>
          </Box>
        </Box>

        <Footer />
      </Box>
    </>
  )
}

export default Contacts

const ArrayView = ({ array }) => {
  const items = []
  for (let i = 0; i < array.length; i++) {
    items.push(
      <li
        style={{
          paddingLeft: "30px",
          color: "#fff",
          fontSize: "14px",
          margin: "24px 0",
        }}
      >
        <ul>
          <li style={{ listStyle: "none" }}>
            <WrapTypo fs="14px" fw="700" mb="8px" color="#fff">
              {array[i].title}
            </WrapTypo>
          </li>
          <li style={{ listStyle: "none" }}>
            <WrapTypo fs="14px" color="#fff">
              {array[i].content}
            </WrapTypo>
          </li>
        </ul>
      </li>
    )
  }
  return (
    <>
      <ol>{items}</ol>
    </>
  )
}

const ArrayView1 = ({ array }) => {
  const items = []
  const a = []
  const b = []
  const c = []
  const d = []
  const e = []
  for (let p = 0; p < array7.length; p++) {
    if (p == 0) {
      e.push(
        <>
          <Box width="85%" margin="auto">
            <Box>
              <li style={{ color: "#fff", fontSize: "14px" }}>
                <WrapTypo fs="14px" fw="700" color="#fff">
                  {array7[p].title}
                </WrapTypo>
              </li>
            </Box>
            <Box>
              <WrapTypo fs="14px" color="#fff">
                名称：株式会社アッドラスト
                <br />
                個人情報保護管理者：代表取締役社長 飯島　三厳
              </WrapTypo>
            </Box>
          </Box>
        </>
      )
    } else if (p == 1) {
      e.push(
        <>
          <Box width="85%" margin="auto">
            <Box>
              <li style={{ color: "#fff", fontSize: "14px" }}>
                <WrapTypo fs="14px" fw="700" color="#fff">
                  {array7[p].title}
                </WrapTypo>
              </li>
            </Box>
            <Box>
              <WrapTypo fs="14px" color="#fff">
                「保有個人情報の利用目的特定表」に記載しました。
              </WrapTypo>
            </Box>
          </Box>
        </>
      )
    } else if (p == 2) {
      e.push(
        <>
          <Box width="85%" margin="auto">
            <Box>
              <li style={{ color: "#fff", fontSize: "14px" }}>
                <WrapTypo fs="14px" fw="700" color="#fff">
                  {array7[p].title}
                </WrapTypo>
              </li>
            </Box>
            <Box>
              <ol
                style={{
                  color: "#fff",
                  fontSize: "18px",
                }}
              >
                <li style={{}}>
                  <WrapTypo fs="14px" color="#fff">
                    開示等の求めのお申し出先にて手続き方法をお問い合わせください。
                    <br />
                    個人情報保護相談窓口
                    <br />
                    〒150-0033　東京都渋谷区猿楽町2-3　CORPO AMAGI 3F"
                  </WrapTypo>
                </li>
              </ol>
            </Box>
          </Box>
        </>
      )
    }
  }
  for (let o = 0; o < array8.length; o++) {
    d.push(
      <>
        <Box width="85%" margin="auto">
          <li style={{ color: "#fff", fontSize: "14px", paddingLeft: "10px" }}>
            <WrapTypo fs="14px" color="#fff">
              {array8[o].title}
            </WrapTypo>
          </li>
        </Box>
      </>
    )
  }
  for (let l = 0; l < array5.length; l++) {
    b.push(
      <>
        <Box width="90%" marginLeft="auto">
          <li style={{ color: "#fff", fontSize: "14px", paddingLeft: "10px" }}>
            <WrapTypo fs="14px" color="#fff">
              {array5[l].title}
            </WrapTypo>
          </li>
        </Box>
      </>
    )
  }
  for (let m = 0; m < array6.length; m++) {
    c.push(
      <>
        <Box width="90%" marginLeft="auto">
          <li style={{ color: "#fff", fontSize: "14px", paddingLeft: "10px" }}>
            <WrapTypo fs="14px" color="#fff">
              {array6[m].title}
            </WrapTypo>
          </li>
        </Box>
      </>
    )
  }
  for (let n = 0; n < array4.length; n++) {
    a.push(
      <>
        <Box>
          <li
            style={{
              color: "#fff",
              fontSize: "14px",
              margin: "24px 0",
              paddingLeft: "30px",
            }}
          >
            <WrapTypo fs="14px" color="#fff">
              {array4[n].tittle}
            </WrapTypo>
          </li>
          <ol>
            {(() => {
              if (n == 0) {
                return b
              } else if (n == 9) {
                return c
              }
              return false
            })()}
          </ol>
          <Box paddingLeft="30px">
            <WrapTypo fs="14px" color="#fff">
              {array4[n].content}
            </WrapTypo>
          </Box>
        </Box>
      </>
    )
  }

  for (let i = 0; i < array.length; i++) {
    if (i == 0) {
      items.push(
        <>
          <li
            style={{
              listStyleType: "upper-roman",
              color: "#fff",
              fontSize: "14px",
              margin: "24px 0",
            }}
          >
            <ul style={{ listStylePosition: "inside" }}>
              <li style={{ listStyle: "none" }}>
                <WrapTypo fs="14px" fw="700" color="#fff">
                  {array[i].title}
                </WrapTypo>
              </li>
            </ul>
          </li>

          <Box>
            <WrapTypo fs="14px" color="#fff">
              {array[i].content}
            </WrapTypo>
          </Box>
          <Box mb={3}>
            <WrapTypo fs="14px" color="#fff">
              個人情報の利用目的特定表
            </WrapTypo>
          </Box>

          <Box mb={3}>
            <Grid container>
              <Grid item xs={12} sm={4} style={{ border: "solid 1px #fff" }}>
                <Box height="100%">
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid item>
                      <WrapTypo fs="14px" color="#fff" mt="8px" mb="8px">
                        当社の業務内容
                      </WrapTypo>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} style={{ border: "solid 1px #fff" }}>
                <ul
                  style={{
                    listStyle: "disc",
                    color: "#fff",
                    fontSize: "14px",
                    listStylePosition: "inside",
                    padding: "16px 0 16px 10px",
                  }}
                >
                  <li>BtoBマーケティング業務</li>
                  <li>データ解析業務（マーケティングオートメーション業務）</li>
                  <li>メディア運営業務</li>
                  <li>その他新規事業</li>
                </ul>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Box mb={3}>
              <WrapTypo fs="14px" fw="700" color="#fff">
                利用目的
              </WrapTypo>
            </Box>
            <Box width="85%" margin="auto">
              <ol>{a}</ol>
            </Box>
          </Box>
        </>
      )
    } else if (i == 1) {
      items.push(
        <>
          <li
            style={{
              listStyleType: "upper-roman",
              color: "#fff",
              fontSize: "14px",
              margin: "24px 0",
            }}
          >
            <ul style={{ listStylePosition: "inside" }}>
              <li style={{ listStyle: "none" }}>
                <WrapTypo fs="14px" color="#fff">
                  {array[i].title}
                </WrapTypo>
              </li>
            </ul>
          </li>
          <Box>
            <WrapTypo>{array[i].content}</WrapTypo>
          </Box>
          <Box>
            <ol>{e}</ol>
          </Box>
        </>
      )
    } else if (i == 2) {
      items.push(
        <>
          <li
            style={{
              listStyleType: "upper-roman",
              color: "#fff",
              fontSize: "14px",
              margin: "24px 0",
            }}
          >
            <ul style={{ listStylePosition: "inside" }}>
              <li style={{ listStyle: "none" }}>
                <WrapTypo fs="14px" color="#fff">
                  {array[i].title}
                </WrapTypo>
              </li>
            </ul>
          </li>
          <Box mb={3}>
            <WrapTypo fs="14px" color="#fff">
              {array[i].content}
            </WrapTypo>
          </Box>
          <Box>
            <ol>{d}</ol>
          </Box>
        </>
      )
    } else {
      items.push(
        <>
          <li
            style={{
              listStyleType: "upper-roman",

              color: "#fff",
              fontSize: "14px",
              margin: "24px 0",
            }}
          >
            <ul style={{ listStylePosition: "inside" }}>
              <li style={{ listStyle: "none" }}>
                <Box>
                  <WrapTypo fs="14px" color="#fff">
                    {array[i].title}
                  </WrapTypo>
                </Box>
              </li>
            </ul>
          </li>
          <Box>
            <WrapTypo fs="14px" color="#fff">
              {array[i].content}
            </WrapTypo>
          </Box>
          <Box>
            <WrapTypo fs="14px" color="#fff">
              {array[i].content2}
            </WrapTypo>
          </Box>
        </>
      )
    }
  }
  return (
    <>
      <ol>{items}</ol>
    </>
  )
}
