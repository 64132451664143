import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { Box, Hidden } from "@material-ui/core"
import styled from "styled-components"
import WrapTypo from "../components/WrapTypo"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"

const RecruitStatement = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: { eq: "skils.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pic1: file(relativePath: { eq: "skil_pic1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pic2: file(relativePath: { eq: "skil_pic2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pic3: file(relativePath: { eq: "skil_pic31.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Box
        width="90%"
        maxWidth="1200px"
        m={"auto"}
        mt={[10, 10, 20, 20, 20]}
        position="relative"
        textAlign="left"
      >
        <Hidden mdUp>
          <Box
            position="absolute"
            top="-1%"
            left="0%"
            width="100%"
            data-aos="fade-right"
            zIndex="10"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={12} md={4} style={{ padding: "32px 0" }}>
              <Box data-aos="fade-in" data-aos-duration="1000">
                <Img fluid={data.pic1.childImageSharp.fluid} />
                <Box border="1px solid #fff" borderRadius="2px" p={1} my={4}>
                  <WrapTypo fs="18" fw="700" color="#fff" ls="3px">
                    仮設検証スキル
                  </WrapTypo>
                </Box>
                <WrapTypo
                  fs="18"
                  lh="180%"
                  color="#fff"
                  style={{ textAlign: "justify" }}
                >
                  顧客に対して提案したサービスの「ニーズを感じてもらえなかった点」「良いと感じてくれた点」を日々考えることで、仮説を立てる力を養える。立てた仮説をすぐに検証し、再び仮説を立てられる。学生主　体で行動している会社だからこそ、仮説と検証のサイクルを早く回すことができ、実践的な学びができる。
                </WrapTypo>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} style={{ padding: "32px 0" }}>
              <Box mt={[2, 2, 12]}>
                <Box
                  data-aos="fade-down"
                  data-aos-duration="1200"
                  data-aos-delay="800"
                >
                  <Img fluid={data.pic2.childImageSharp.fluid} />
                  <Box border="1px solid #fff" borderRadius="2px" p={1} my={4}>
                    <WrapTypo fs="18" fw="700" color="#fff" ls="3px">
                      コミュニケーション能力
                    </WrapTypo>
                  </Box>
                  <WrapTypo
                    fs="18"
                    lh="180%"
                    color="#fff"
                    style={{ textAlign: "justify" }}
                  >
                    弊社の支援している案件は新しいサービスが多いため、サービスについて全く知らない顧客に対し、「明確にわかりやすく」内容を伝える必要がある。「どんな順序で、どんな言葉を使って」伝えるかを考え、実践するため、コミュニケーション能力が身につく。
                  </WrapTypo>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} style={{ padding: "32px 0" }}>
              <Box mt={[2, 2, 24]}>
                <Box
                  data-aos="fade-down"
                  data-aos-duration="2400"
                  data-aos-delay="1600"
                >
                  <Img fluid={data.pic3.childImageSharp.fluid} />
                  <Box border="1px solid #fff" borderRadius="2px" p={1} my={4}>
                    <WrapTypo fs="18" fw="700" color="#fff" ls="3px">
                      幅広い交友関係
                    </WrapTypo>
                  </Box>
                  <WrapTypo
                    fs="18"
                    lh="180%"
                    color="#fff"
                    style={{ textAlign: "justify" }}
                  >
                    全国の約70大学から大学生が集まるので、大学でのコミュニティとは別に、新しいコミュニティができる。学生主体で動いているからこそ、他のインターン生から教わる機会も増え、関わりが密になる。またチームで働くことが多いので、協力し、成功した時の達成感を共に味わうことができる。
                  </WrapTypo>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Box
            position="absolute"
            top="-10%"
            left="0%"
            width="100%"
            data-aos="fade-right"
            zIndex="10"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={12} md={4} style={{ padding: "32px" }}>
              <Box data-aos="fade-in" data-aos-duration="1000">
                <Img fluid={data.pic1.childImageSharp.fluid} />
                <Box border="1px solid #fff" borderRadius="2px" p={1} my={4}>
                  <WrapTypo fs="18" fw="700" color="#fff" ls="3px">
                    仮設検証スキル
                  </WrapTypo>
                </Box>
                <WrapTypo
                  fs="18"
                  lh="180%"
                  color="#fff"
                  style={{ textAlign: "justify" }}
                >
                  顧客に対して提案したサービスの「ニーズを感じてもらえなかった点」「良いと感じてくれた点」を日々考えることで、仮説を立てる力を養える。立てた仮説をすぐに検証し、再び仮説を立てられる。学生主　体で行動している会社だからこそ、仮説と検証のサイクルを早く回すことができ、実践的な学びができる。
                </WrapTypo>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} style={{ padding: "32px" }}>
              <Box mt={[2, 2, 12]}>
                <Box
                  data-aos="fade-down"
                  data-aos-duration="1200"
                  data-aos-delay="800"
                >
                  <Img fluid={data.pic2.childImageSharp.fluid} />
                  <Box border="1px solid #fff" borderRadius="2px" p={1} my={4}>
                    <WrapTypo fs="18" fw="700" color="#fff" ls="3px">
                      コミュニケーション能力
                    </WrapTypo>
                  </Box>
                  <WrapTypo
                    fs="18"
                    lh="180%"
                    color="#fff"
                    style={{ textAlign: "justify" }}
                  >
                    弊社の支援している案件は新しいサービスが多いため、サービスについて全く知らない顧客に対し、「明確にわかりやすく」内容を伝える必要がある。「どんな順序で、どんな言葉を使って」伝えるかを考え、実践するため、コミュニケーション能力が身につく。
                  </WrapTypo>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} style={{ padding: "32px" }}>
              <Box mt={[2, 2, 24]}>
                <Box
                  data-aos="fade-down"
                  data-aos-duration="2400"
                  data-aos-delay="1600"
                >
                  <Img fluid={data.pic3.childImageSharp.fluid} />
                  <Box border="1px solid #fff" borderRadius="2px" p={1} my={4}>
                    <WrapTypo fs="18" fw="700" color="#fff" ls="3px">
                      幅広い交友関係
                    </WrapTypo>
                  </Box>
                  <WrapTypo
                    fs="18"
                    lh="180%"
                    color="#fff"
                    style={{ textAlign: "justify" }}
                  >
                    全国の約70大学から大学生が集まるので、大学でのコミュニティとは別に、新しいコミュニティができる。学生主体で動いているからこそ、他のインターン生から教わる機会も増え、関わりが密になる。またチームで働くことが多いので、協力し、成功した時の達成感を共に味わうことができる。
                  </WrapTypo>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
      </Box>
    </>
  )
}

export default RecruitStatement
