import React from "react"
import bgImg from "../images/fv_background.png"
import Img from "gatsby-image"
import "./index.css"
import "../style/font-style.css"
import Box from "@material-ui/core/Box"
import AOS from "aos"
import "aos/dist/aos.css"
import { useStaticQuery, graphql, Link } from "gatsby"
import MainHead from "../components/head"
import Header from "../components/Header_normal"
import RecentNews from "../components/RecentNews"
import Profile from "../components/Profile"
import About from "../components/About"
import Content from "../components/content"
import Footer from "../components/footer"
import Pastevent from "../components/pastevent"
import { Hidden } from "@material-ui/core"
import Recurit from "../components/Recruit"
import Compony from "../components/Company"
import Layout from "../components/layout"

{
  /* <h1
  style={{
    background:
      "linear-gradient( to right,  rgba(69,179,224,1) 25%, rgba(0,51,153,1) 75% )",
    WebkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
  }}
>
  fasfasd
        </h1> */
}
export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    })
  }
  const data = useStaticQuery(graphql`
    query {
      pc_top: file(relativePath: { eq: "pc_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_top: file(relativePath: { eq: "sp_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cta: file(relativePath: { eq: "cta_contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      addrust_logo: file(relativePath: { eq: "fv_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Layout>
        <MainHead />
        <Box width="100%" bgcolor="#242424">
          <Header />
          <Box
            className="fv"
            width="100%"
            mx={"auto"}
            py="32px"
            position="relative"
            style={{
              backgroundImage: `url(${bgImg})`,
              backgroundRepeat: "repeat",
              backgroundSize: "contain",
            }}
          >
            <Hidden xsDown>
              <Box width="100%" data-aos="fade-in">
                <Img
                  style={{
                    width: "100%",
                    marginTop: "20%",
                    marginBottom: "20%",
                  }}
                  fluid={data.addrust_logo.childImageSharp.fluid}
                />
              </Box>
            </Hidden>

            <Hidden smUp>
              <Box width="100%" data-aos="fade-in">
                <Img
                  style={{
                    width: "100%",
                    marginTop: "20%",
                    marginBottom: "20%",
                  }}
                  fluid={data.addrust_logo.childImageSharp.fluid}
                />
              </Box>
            </Hidden>
          </Box>
          <Profile />
          <About />
          <Content />
          <Recurit />
          <Compony />
          <Footer />
        </Box>
      </Layout>
    </>
  )
}
