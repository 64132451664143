import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "./WrapTypo"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"
import { Hidden } from "@material-ui/core"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: { eq: "about.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about1: file(relativePath: { eq: "about1.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about2: file(relativePath: { eq: "about2.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about3: file(relativePath: { eq: "about3.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const items = [
    {
      title: "アウトバウンド型 マーケティング支援事業",
      img: data.about1.childImageSharp.fluid,
      content:
        "「事業の加速拡大に向けた最適解」として、年間300社以上の企業様と提携させていただいており、各クライアントのビジネスモデルや社内体制に沿って、複合的な観点から適切なマーケティング施策をご提供いたします。",
    },
    {
      title: "「0円採用×0日就活」FreeStyleTicketの運営",
      img: data.about2.childImageSharp.fluid,
      content:
        "「当たり前を見直す」全国約70大学に在籍している大学生250名を無料でご紹介する、業界初の「完全無料型」人材紹介サービス、FreeStyle Ticketを運営しております。",
      link: "http://freestyle-ticket.com/",
      linkName: "FreeStyleTicketの詳細ページはこちら",
    },
    {
      title: "営業＆教育 コンサルティング事業",
      img: data.about3.childImageSharp.fluid,
      content:
        "「目標が達成できる営業パーソン」になるための教育カリキュラムをベースにＯＪＴのサポートを行います。 また既存社員には、新しい人材が入ってきた際のマネジメント方法や、モチベーション管理の方法を共有する事で、離職率の軽減にも繋がります。",
    },
  ]
  return (
    <>
      <Box id="about">　</Box>
      <Box>
        <Hidden smDown>
          <Box
            width="100%"
            maxWidth="1200px"
            m={"auto"}
            mt="200px"
            position="relative"
          >
            <Box
              position="absolute"
              top="-10%"
              left="0%"
              width="100%"
              data-aos="fade-right"
              zIndex="10"
            >
              <Img
                fluid={data.title.childImageSharp.fluid}
                style={{ width: "60%", minWidth: "300px" }}
              />
            </Box>
            <Box>
              {items.map((c, i) => {
                return (
                  <>
                    <Hidden smDown>
                      <Box
                        mt="120px"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                      >
                        <Grid container>
                          <Grid items xs={6}>
                            <Img fluid={c.img} />
                          </Grid>
                          <Grid items xs={6}>
                            <Box
                              width="100%"
                              m="auto"
                              mt="28px"
                              style={{
                                border: "solid 1px #fff",
                                textAlign: "center",
                              }}
                            >
                              <WrapTypo
                                fw="700"
                                fs="24px"
                                mt="15px"
                                mb="15px"
                                ls="9.5%"
                                color="white"
                                style={{ margin: "auto" }}
                              >
                                {c.title}
                              </WrapTypo>
                            </Box>
                            <Box
                              width="415px"
                              m={"auto"}
                              mt="46px"
                              textAlign="justify"
                            >
                              <WrapTypo
                                fs="18px"
                                lh="200%"
                                ls="5%"
                                color="white"
                              >
                                {c.content}
                              </WrapTypo>
                              {c.link ? (
                                <Link to={c.link}>
                                  <WrapTypo
                                    fs="18px"
                                    lh="200%"
                                    ls="5%"
                                    color="white"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    <br />
                                    {c.linkName}
                                  </WrapTypo>
                                </Link>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Hidden>
                    <Hidden mdUp>
                      <Box mt="120px" width="100%">
                        <Img fluid={c.img} style={{ width: "100%" }} />
                        <Box
                          width="100%"
                          m="auto"
                          style={{
                            border: "solid 1px #fff",
                            textAlign: "center",
                          }}
                        >
                          <WrapTypo
                            fw="700"
                            fs="16px"
                            mt="15px"
                            mb="15px"
                            ls="9.5%"
                            color="white"
                            style={{ margin: "auto" }}
                          >
                            {c.title}
                          </WrapTypo>
                        </Box>
                        <Box
                          width="90%"
                          m={"auto"}
                          mt="46px"
                          textAlign="justify"
                        >
                          <WrapTypo fs="16px" lh="180%" ls="5%" color="white">
                            {c.content}
                          </WrapTypo>
                          {c.link ? (
                            <Link to={c.link}>
                              <WrapTypo
                                fs="18px"
                                lh="180%"
                                ls="5%"
                                color="white"
                                style={{ textDecoration: "underline" }}
                              >
                                {c.linkName}
                              </WrapTypo>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </Hidden>
                  </>
                )
              })}
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box
            width="100%"
            maxWidth="1200px"
            m={"auto"}
            mt="200px"
            position="relative"
          >
            <Box
              position="absolute"
              top="-5%"
              left="0px"
              width="100%"
              data-aos="fade-right"
              zIndex="10"
            >
              <Img
                fluid={data.title.childImageSharp.fluid}
                style={{ width: "80%", minWidth: "300px" }}
              />
            </Box>
            <Box>
              {items.map((c, i) => {
                return (
                  <>
                    <Hidden smDown>
                      <Box
                        mt="120px"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                      >
                        <Grid container>
                          <Grid items xs={6}>
                            <Img fluid={c.img} />
                          </Grid>
                          <Grid items xs={6}>
                            <Box
                              width="100%"
                              m="auto"
                              mt="28px"
                              style={{
                                border: "solid 1px #fff",
                                textAlign: "center",
                              }}
                            >
                              <WrapTypo
                                fw="700"
                                fs="24px"
                                mt="15px"
                                mb="15px"
                                ls="9.5%"
                                color="white"
                                style={{ margin: "auto" }}
                              >
                                {c.title}
                              </WrapTypo>
                            </Box>
                            <Box
                              width="415px"
                              m={"auto"}
                              mt="46px"
                              textAlign="justify"
                            >
                              <WrapTypo
                                fs="18px"
                                lh="200%"
                                ls="5%"
                                color="white"
                              >
                                {c.content}
                              </WrapTypo>
                              {c.link ? (
                                <Link to={c.link}>
                                  <WrapTypo
                                    fs="18px"
                                    lh="180%"
                                    ls="5%"
                                    color="white"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    {c.linkName}
                                  </WrapTypo>
                                </Link>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Hidden>
                    <Hidden mdUp>
                      <Box mt="120px" width="100%">
                        <Img fluid={c.img} style={{ width: "100%" }} />
                        <Box
                          width="100%"
                          m="auto"
                          style={{
                            border: "solid 1px #fff",
                            textAlign: "center",
                          }}
                        >
                          <WrapTypo
                            fw="700"
                            fs="16px"
                            mt="15px"
                            mb="15px"
                            ls="9.5%"
                            color="white"
                            style={{ margin: "auto" }}
                          >
                            {c.title}
                          </WrapTypo>
                        </Box>
                        <Box
                          width="90%"
                          m={"auto"}
                          mt="46px"
                          textAlign="justify"
                        >
                          <WrapTypo fs="16px" lh="180%" ls="5%" color="white">
                            {c.content}
                          </WrapTypo>
                          {c.link ? (
                            <Link to={c.link}>
                              <WrapTypo
                                fs="16px"
                                lh="180%"
                                ls="5%"
                                color="white"
                                style={{ textDecoration: "underline" }}
                              >
                                <br />
                                {c.linkName}
                              </WrapTypo>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </Hidden>
                  </>
                )
              })}
            </Box>
          </Box>
        </Hidden>
      </Box>
    </>
  )
}

export default About
