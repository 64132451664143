import React from "react"
import bgImg from "../images/fv_background.png"
import Img from "gatsby-image"
import "./index.css"
import "../style/font-style.css"
import Box from "@material-ui/core/Box"
import AOS from "aos"
import "aos/dist/aos.css"
import { useStaticQuery, graphql, Link } from "gatsby"
import MainHead from "../components/head"
import Header from "../components/Header_normal"
import RecentNews from "../components/RecentNews"
import Profile from "../components/Profile"
import About from "../components/About"
import Content from "../components/content"
import Footer from "../components/footer"
import Pastevent from "../components/pastevent"
import { Hidden } from "@material-ui/core"
import Recurit from "../components/Recruit"
import Compony from "../components/Company"
import RecruitState from "../components/RecruitStatement"
import Skils from "../components/Skils"
import Details from "../components/Details"
import Stories from "../components/stories"

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    })
  }
  const data = useStaticQuery(graphql`
    query {
      pc_top: file(relativePath: { eq: "pc_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_top: file(relativePath: { eq: "sp_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recruit_back_img_xs: file(
        relativePath: { eq: "recruit_back_img_xs.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recruit_back_img: file(relativePath: { eq: "Group2147.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <MainHead />
      <Box width="100%" bgcolor="#242424">
        <Header />
        <Box>
          <Hidden mdUp>
            <Box width="100%">
              <Img
                style={{ width: "100%" }}
                fluid={data.recruit_back_img_xs.childImageSharp.fluid}
              />
            </Box>
          </Hidden>
          <Hidden smDown>
            <Box width="100%">
              <Img
                style={{ width: "100%" }}
                fluid={data.recruit_back_img.childImageSharp.fluid}
              />
            </Box>
          </Hidden>
        </Box>
        <RecruitState />
        <Skils />
        <Details />
        <Stories />
        <Compony />
        <Footer />
      </Box>
    </>
  )
}
