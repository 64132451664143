import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "../components/WrapTypo"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"
import { Hidden } from "@material-ui/core"

const RecruitStatement = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: { eq: "details.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pic1: file(relativePath: { eq: "skil_pic1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pic2: file(relativePath: { eq: "skil_pic2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pic3: file(relativePath: { eq: "skil_pic31.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Box
        width="90%"
        maxWidth="1200px"
        m={"auto"}
        mt={[18, 10, 20, 20, 20]}
        position="relative"
        textAlign="left"
      >
        <Hidden mdUp>
          <Box
            position="absolute"
            top="-3%"
            left="-10%"
            width="100%"
            data-aos="fade-right"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box
            position="absolute"
            top="-10%"
            left="-10%"
            width="100%"
            data-aos="fade-right"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
        </Hidden>

        <Box data-aos="fade-in" data-aos-duration="1000">
          <Hidden smUp>
            <WrapTypo color="#fff" fs="18" fw="700" color="#fff" ls="3px">
              【業務内容】
            </WrapTypo>
            <WrapTypo
              mt={"16px"}
              color="#fff"
              fs="18px"
              lh="180%"
              style={{ textAlign: "justify" }}
            >
              ◆BtoBマーケティング業務
              <br />
              当社独自ノウハウによるアウトバウンド型のマーケティング戦略によって、主にBtoB企業の商品プロモーション支援を行います。
              <br />
              <br />
              ◆メディア運営業務
              <br />
              「学生が運営するメディア」として当社が運営している「Jobby」の記事作成や企画立案を行います。
              <br />
              <br />
              ◆データ解析業務（マーケティングオートメーション業務）
              <br />
              集計したデータに基づいて、支援企業のビジネスモデルをスケールさせていくための戦略を構築します。
              <br />
              <br />
              ◆新規事業
              <br />
              上記以外にも学生が新規事業をやりたいといった場合、会社として積極的にサポートして「学生だけで行う事業部」を増やしています。
            </WrapTypo>
            <WrapTypo
              color="#fff"
              fs="18"
              fw="700"
              color="#fff"
              ls="3px"
              mt={"64px"}
            >
              【給与】
            </WrapTypo>
            <WrapTypo
              mt={"16px"}
              color="#fff"
              fs="18px"
              lh="180%"
              style={{ textAlign: "justify" }}
            >
              時給 1,013円〜1,700円 （昇給あり）
              <br />
              <br />
              ＊時給は1,013円スタートです。「成長したら随時」昇給・昇格があります。
              <br />
              <br />
              ＊昇格基準は定量評価で決まっており、条件をクリアすればすぐに昇格します。
              <br />
              ー過去の例ですと、入社して3ヶ月で時給が600円アップした先輩もいます。
              <br />
              ー現在の平均時給は1,250円です。
            </WrapTypo>
            <WrapTypo
              color="#fff"
              fs="18"
              fw="700"
              color="#fff"
              ls="3px"
              mt={"64px"}
            >
              【オフィス所在地】
            </WrapTypo>
            <WrapTypo
              mt={"16px"}
              color="#fff"
              fs="18px"
              lh="180%"
              style={{ textAlign: "justify" }}
            >
              東京都渋谷区猿楽町2-3コーポ天城305 <br />
              (山手線 渋谷駅 徒歩3分) <br /> <br />
              大阪オフィス：大阪府大阪市北区太融寺町2-21 ニュープラザビル609{" "}
              <br />
              (JR大阪駅　徒歩12分 ー阪神電鉄 梅田駅　徒歩10分)
            </WrapTypo>
            <WrapTypo
              color="#fff"
              fs="18"
              fw="700"
              color="#fff"
              ls="3px"
              mt={"64px"}
            >
              【シフト・勤務時間】
            </WrapTypo>
            <WrapTypo
              mt={"16px"}
              color="#fff"
              fs="18px"
              lh="180%"
              style={{ textAlign: "justify" }}
            >
              週2日以上、1日1時間以上
              <br />
              10：00 ～ 19：00 <br />
              <br />
              ※交替制勤務 平日10時～19時までの出勤時間で、
              自由に、あなたのペースでシフトに入ってください！
            </WrapTypo>
            <WrapTypo
              color="#fff"
              fs="18"
              fw="700"
              color="#fff"
              ls="3px"
              mt={"64px"}
            >
              【勤務できる曜日】
            </WrapTypo>
            <WrapTypo
              mt={"16px"}
              color="#fff"
              fs="18px"
              lh="180%"
              style={{ textAlign: "justify" }}
            >
              月、火、水、木、金
            </WrapTypo>
            <WrapTypo
              color="#fff"
              fs="18"
              fw="700"
              color="#fff"
              ls="3px"
              mt={"64px"}
            >
              【応募資格】
            </WrapTypo>
            <WrapTypo
              mt={"16px"}
              color="#fff"
              fs="18px"
              lh="180%"
              style={{ textAlign: "justify" }}
            >
              高卒以上、未経験OK、服装・髪型自由
            </WrapTypo>
            <WrapTypo
              color="#fff"
              fs="18"
              fw="700"
              color="#fff"
              ls="3px"
              mt={"64px"}
            >
              【待遇・応募資格】
            </WrapTypo>
            <WrapTypo
              mt={"16px"}
              color="#fff"
              fs="18px"
              lh="180%"
              style={{ textAlign: "justify" }}
            >
              メンバー旅行　今年は山梨、沖縄にいきました。来年は海外にいけるかも!?
              社員登用、まかない（食事）あり
            </WrapTypo>
          </Hidden>
          <Hidden smDown>
            <table
              style={{
                align: "center",
                border: "solid 1px #fff",
                borderCollapse: "collapse",
              }}
            >
              <tr style={{ border: "solid 1px #fff" }}>
                <td
                  style={{
                    border: "solid 1px #fff",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <WrapTypo color="#fff" fs="18" fw="700" ls="3px">
                    【業務内容】
                  </WrapTypo>
                </td>
                <td
                  style={{
                    border: "solid 1px #fff",
                    textAlign: "center",
                    padding: "24px",
                  }}
                >
                  <WrapTypo
                    mt={"16px"}
                    color="#fff"
                    fs="18px"
                    lh="180%"
                    style={{ textAlign: "justify" }}
                  >
                    ◆BtoBマーケティング業務
                    <br />
                    当社独自ノウハウによるアウトバウンド型のマーケティング戦略によって、主にBtoB企業の商品プロモーション支援を行います。
                    <br />
                    <br />
                    ◆メディア運営業務
                    <br />
                    「学生が運営するメディア」として当社が運営している「Jobby」の記事作成や企画立案を行います。
                    <br />
                    <br />
                    ◆データ解析業務（マーケティングオートメーション業務）
                    <br />
                    集計したデータに基づいて、支援企業のビジネスモデルをスケールさせていくための戦略を構築します。
                    <br />
                    <br />
                    ◆新規事業
                    <br />
                    上記以外にも学生が新規事業をやりたいといった場合、会社として積極的にサポートして「学生だけで行う事業部」を増やしています。
                  </WrapTypo>
                </td>
              </tr>
              <tr style={{ border: "solid 1px #fff" }}>
                <td style={{ border: "solid 1px #fff", textAlign: "center" }}>
                  <WrapTypo color="#fff" fs="18" fw="700" color="#fff" ls="3px">
                    【給与】
                  </WrapTypo>
                </td>
                <td style={{ border: "solid 1px #fff", padding: "24px" }}>
                  <WrapTypo
                    mt={"16px"}
                    color="#fff"
                    fs="18px"
                    lh="180%"
                    style={{ textAlign: "justify" }}
                  >
                    時給 1,013円〜1,700円 （昇給あり）
                    <br />
                    <br />
                    ＊時給は1,013円スタートです。「成長したら随時」昇給・昇格があります。
                    <br />
                    <br />
                    ＊昇格基準は定量評価で決まっており、条件をクリアすればすぐに昇格します。
                    <br />
                    ー過去の例ですと、入社して3ヶ月で時給が600円アップした先輩もいます。
                    <br />
                    ー現在の平均時給は1,250円です。
                  </WrapTypo>
                </td>
              </tr>
              <tr style={{ border: "solid 1px #fff" }}>
                <td
                  style={{
                    border: "solid 1px #fff",
                    textAlign: "center",
                  }}
                >
                  <WrapTypo color="#fff" fs="18" fw="700" color="#fff" ls="3px">
                    【オフィス所在地】
                  </WrapTypo>
                </td>
                <td style={{ border: "solid 1px #fff", padding: "24px" }}>
                  <WrapTypo
                    mt={"16px"}
                    color="#fff"
                    fs="18px"
                    lh="180%"
                    style={{ textAlign: "justify" }}
                  >
                    東京都渋谷区猿楽町2-3コーポ天城305 <br />
                    (山手線 渋谷駅 徒歩3分) <br /> <br />
                    大阪オフィス：大阪府大阪市北区太融寺町2-21
                    ニュープラザビル609 <br />
                    (JR大阪駅　徒歩12分 ー阪神電鉄 梅田駅　徒歩10分)
                  </WrapTypo>
                </td>
              </tr>
              <tr style={{ border: "solid 1px #fff" }}>
                <td
                  style={{
                    border: "solid 1px #fff",
                    textAlign: "center",
                  }}
                >
                  <WrapTypo color="#fff" fs="18" fw="700" color="#fff" ls="3px">
                    【シフト・勤務時間】
                  </WrapTypo>
                </td>
                <td style={{ border: "solid 1px #fff", padding: "24px" }}>
                  <WrapTypo
                    mt={"16px"}
                    color="#fff"
                    fs="18px"
                    lh="180%"
                    style={{ textAlign: "justify" }}
                  >
                    週2日以上、1日1時間以上
                    <br />
                    10：00 ～ 19：00 <br />
                    <br />
                    ※交替制勤務 平日10時～19時までの出勤時間で、
                    自由に、あなたのペースでシフトに入ってください！
                  </WrapTypo>
                </td>
              </tr>
              <tr style={{ border: "solid 1px #fff" }}>
                <td style={{ border: "solid 1px #fff", textAlign: "center" }}>
                  <WrapTypo color="#fff" fs="18" fw="700" color="#fff" ls="3px">
                    【勤務できる曜日】
                  </WrapTypo>
                </td>
                <td style={{ border: "solid 1px #fff", padding: "24px" }}>
                  <WrapTypo
                    mt={"16px"}
                    color="#fff"
                    fs="18px"
                    lh="180%"
                    style={{ textAlign: "justify" }}
                  >
                    月、火、水、木、金
                  </WrapTypo>
                </td>
              </tr>
              <tr style={{ border: "solid 1px #fff" }}>
                <td style={{ border: "solid 1px #fff", textAlign: "center" }}>
                  <WrapTypo color="#fff" fs="18" fw="700" color="#fff" ls="3px">
                    【応募資格】
                  </WrapTypo>
                </td>
                <td style={{ border: "solid 1px #fff" }}>
                  <WrapTypo
                    mt={"16px"}
                    color="#fff"
                    fs="18px"
                    lh="180%"
                    style={{ textAlign: "justify", padding: "24px" }}
                  >
                    高卒以上、未経験OK、服装・髪型自由
                  </WrapTypo>
                </td>
              </tr>
              <tr style={{ border: "solid 1px #fff" }}>
                <td
                  style={{
                    border: "solid 1px #fff",
                    textAlign: "center",
                    padding: "24px",
                  }}
                >
                  <WrapTypo color="#fff" fs="18" fw="700" color="#fff" ls="3px">
                    【待遇・応募資格】
                  </WrapTypo>
                </td>
                <td style={{ border: "solid 1px #fff", padding: "24px" }}>
                  <WrapTypo
                    mt={"16px"}
                    color="#fff"
                    fs="18px"
                    lh="180%"
                    style={{ textAlign: "justify" }}
                  >
                    メンバー旅行　今年は山梨、沖縄にいきました。来年は海外にいけるかも!?
                    社員登用、まかない（食事）あり
                  </WrapTypo>
                </td>
              </tr>
            </table>
          </Hidden>

          <Box
            width="95%"
            maxWidth="800px"
            textAlign="center"
            m={"auto"}
            mt={[10, 10, 20]}
          >
            <Hidden smDown>
              <WrapTypo color="#fff" fw="700" fs="22px">
                就職前に「会社経営」をすることができる！
              </WrapTypo>
            </Hidden>
            <Hidden mdUp>
              <WrapTypo
                color="#fff"
                fw="700"
                fs="22px"
                style={{ textAlign: "left" }}
              >
                就職前に「会社経営」をすることができる！
              </WrapTypo>
            </Hidden>
            <WrapTypo
              style={{ textAlign: "justify" }}
              color="#fff"
              fw="700"
              mt="32px"
              lh="200%"
            >
              毎年、メンバーが就職活動をする際に、様々な企業の面接官から聞かれる質問があるそうです。
              <br />
              「キミはアルバイトをやってきたのか？会社経営をしてきたのか？」
              <br />
              当社最大の特徴として、会社から依頼される「業務」などは存在しません。仲間同士で、目標やミッションを考え、達成に向けたプロセスを描く。経営、マネジメント、教育、経理計算、書類作成、営業活動、全てのシーンで活躍できる人材を輩出しているので、自分に自信をつけたい方には最適だと思います。
            </WrapTypo>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default RecruitStatement
