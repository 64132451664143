import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Hidden } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "./WrapTypo"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"

const Company = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: { eq: "company.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recurit_img: file(relativePath: { eq: "recurit_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      joby_logo: file(relativePath: { eq: "joby_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      media_cta: file(relativePath: { eq: "media_cta.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Box id="company"></Box>
      <Box
        width="100%"
        maxWidth="1200px"
        m={"auto"}
        mt="200px"
        maxWidth="1200px"
        position="relative"
        zIndex={99}
      >
        <Hidden mdUp>
          <Box
            position="absolute"
            top="-40px"
            left="-5%"
            width="100%"
            data-aos="fade-right"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box
            position="absolute"
            top="-25%"
            left="-10%"
            width="100%"
            data-aos="fade-right"
          >
            <a href="/">
              <Img
                fluid={data.title.childImageSharp.fluid}
                style={{ width: "60%", minWidth: "300px" }}
              />
            </a>
          </Box>
        </Hidden>
        <Box data-aos="fade-in" data-aos-duration="1000">
          <Box maxWidth="600px" margin="auto">
            <table height="250px" cellpadding="5" style={{ margin: "auto" }}>
              <tr>
                <td style={{ width: "30%" }}>
                  <WrapTypo fw="700" color="#fff">
                    社名
                  </WrapTypo>
                </td>
                <td>
                  <WrapTypo fw="700" color="#fff">
                    株式会社アッドラスト
                  </WrapTypo>
                </td>
              </tr>
              <tr>
                <td>
                  <WrapTypo fw="700" color="#fff">
                    英文社名
                  </WrapTypo>
                </td>
                <td>
                  <WrapTypo fw="700" color="#fff">
                    Addrust Co., Ltd.
                  </WrapTypo>
                </td>
              </tr>
              <tr>
                <td>
                  <WrapTypo fw="700" color="#fff">
                    代表者
                  </WrapTypo>
                </td>
                <td>
                  <WrapTypo fw="700" color="#fff">
                    代表取締役社長　飯島三厳
                  </WrapTypo>
                </td>
              </tr>
              <tr>
                <td>
                  <WrapTypo fw="700" color="#fff">
                    設立
                  </WrapTypo>
                </td>
                <td>
                  <WrapTypo fw="700" color="#fff">
                    2010年 10月 13日
                  </WrapTypo>
                </td>
              </tr>
            </table>
          </Box>
          <Box mt="32px" textAlign="center">
            <Grid container justify="center">
              <Grid item xs={12} md={5}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.9682393033177!2d139.70271021561163!3d35.653154139117994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b5b3c01de21%3A0x460602f0b58b2584!2z44CSMTUwLTAwMzMg5p2x5Lqs6YO95riL6LC35Yy654y_5qW955S677yS4oiS77yTIOOCs-ODvOODneWkqeWfjg!5e0!3m2!1sja!2sjp!4v1675179890351!5m2!1sja!2sjp"
                  style={{ border: "0", margin: "auto" }}
                  width="80%"
                  height="300px"
                  frameborder="0"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
                <Box textAlign="left" width="80%" m={"auto"} mb="32px">
                  <WrapTypo color="#fff" mt="16px" fw="700">
                    東京本社
                  </WrapTypo>
                  <WrapTypo color="#fff" mt="16px">
                    〒150-0033
                  </WrapTypo>
                  <WrapTypo color="#fff" mt="16px">
                    東京都渋谷区猿楽町2-3 コーポ天城305
                  </WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3280.124850110388!2d135.50315591526567!3d34.7020307906546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e6ea8c2258f9%3A0x2c644bb2d5c7d18a!2z44CSNTMwLTAwNTEg5aSn6Ziq5bqc5aSn6Ziq5biC5YyX5Yy65aSq6J6N5a-655S677yS4oiS77yS77yRIOODi-ODpeODvOODl-ODqeOCtuODk-ODqw!5e0!3m2!1sja!2sjp!4v1611595617748!5m2!1sja!2sjp"
                  style={{ border: "0" }}
                  frameborder="0"
                  width="80%"
                  height="300px"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
                <Box textAlign="left" width="80%" m={"auto"} mb="32px">
                  <WrapTypo color="#fff" mt="16px" fw="700">
                    大阪本社
                  </WrapTypo>
                  <WrapTypo color="#fff" mt="16px">
                    〒530-0051
                  </WrapTypo>
                  <WrapTypo color="#fff" mt="16px">
                    大阪府大阪市北区太融寺町2-21　ニュープラザビル609
                  </WrapTypo>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Company
