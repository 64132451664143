import { Box, Hidden, Typography, Button } from "@material-ui/core"
import styled from "styled-components"
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"
import "../style/font-style.css"

const WrapTypoStyled = styled(Typography)`
  font-family: ${props =>
    props.Notosans
      ? "Noto Sans JP, sans-serif"
      : props.Yumincho
        ? "yumincho,serif"
        : "Roboto, helvetica ,sans-serif"} !important;
  color: ${props => (props.color ? props.color : "#717171")};
  line-height: ${props => (props.lh ? props.lh : "150%")} !important;
  letter-spacing: ${props => (props.ls ? props.ls : "normal")} !important;
  font-weight: ${props => (props.fw ? props.fw : 500)} !important;
  margin-top: ${props => (props.mt ? props.mt : 0)} !important;
  margin-left: ${props => (props.ml ? props.ml : 0)} !important;
  margin-right: ${props => (props.mr ? props.mr : 0)} !important;
  margin-bottom: ${props => (props.mb ? props.mb : 0)} !important;
  font-size: ${props => (props.fs ? props.fs : "")};
  font-style: normal;
  @media screen and (max-width: 600px) {
    text-align: ${props => (props.xsta ? props.xsta : "")};
    font-size: ${props => (props.xsfs ? props.xsfs : "")};
  }
  @media screen and (min-width: 600px) and (max-width: 1280px) {
    font-size: ${props => (props.mdfs ? props.mdfs : "")};
    letter-spacing: ${props => (props.mdls ? props.mdls : "normal")};
    font-weight: ${props => (props.mdfw ? props.mdfw : 500)};
    margin-top: ${props => (props.mdmt ? props.mdmt : 0)};
    margin-left: ${props => (props.mdml ? props.mdml : 0)};
    margin-right: ${props => (props.mdmr ? props.mdmr : 0)};
    margin-bottom: ${props => (props.mdmb ? props.mdmb : 0)};
    font-size: ${props => (props.fs ? props.fs : "")};
  }
  @media screen and (min-width: 1280px) {
    font-size: ${props => (props.lgfs ? props.lgfs : "")};
    letter-spacing: ${props => (props.lgls ? props.lgls : "normal")};
    font-weight: ${props => (props.lgfw ? props.lgfw : 500)};
    margin-top: ${props => (props.lgmt ? props.lgmt : 0)};
    margin-left: ${props => (props.lgml ? props.lgml : 0)};
    margin-right: ${props => (props.lgmr ? props.lgmr : 0)};
    margin-bottom: ${props => (props.lgmb ? props.lgmb : 0)};
    font-size: ${props => (props.fs ? props.fs : "")};
  }
`

export default WrapTypoStyled
