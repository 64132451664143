import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "../components/WrapTypo"
import Drawer from "@material-ui/core/Drawer"

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const sp_mtSize = "40px"
  const data = useStaticQuery(graphql`
    query {
      menuBar: file(relativePath: { eq: "menuBotton.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      close: file(relativePath: { eq: "close.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      addrust_logo: file(relativePath: { eq: "addrust_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Hidden smDown>
        <Box width="95%" mx={"auto"} py={"24px"}>
          <Grid container wrap="wrap" alignItems="center">
            <Grid item xs={1} justify="center">
              <Link to="/">
                <Img fluid={data.addrust_logo.childImageSharp.fluid} />
              </Link>
            </Grid>
            <Grid item xs={5} justify="center"></Grid>
            <Grid item xs={1}>
              <Link to="/">
                <WrapTypo ls="3px" color="#fff">
                  TOP
                </WrapTypo>
              </Link>
            </Grid>
            <Grid item xs={1}>
              <Link to="/#about">
                <WrapTypo ls="3px" color="#fff">
                  ABOUT
                </WrapTypo>
              </Link>
            </Grid>
            <Grid item xs={1}>
              <Link to="/#media">
                <WrapTypo ls="3px" color="#fff">
                  MEDIA
                </WrapTypo>
              </Link>
            </Grid>
            <Grid item xs={1}>
              <Link to="/recruit">
                <WrapTypo ls="3px" color="#fff">
                  RECRUIT
                </WrapTypo>
              </Link>
            </Grid>
            <Grid item xs={1}>
              <Link to="/#company">
                <WrapTypo ls="3px" color="#fff">
                  COMPANY
                </WrapTypo>
              </Link>
            </Grid>
            <Grid item xs={1}>
              <a href="/contacts">
                <WrapTypo ls="3px" color="#fff">
                  CONTACT
                </WrapTypo>
              </a>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box width="90%" m={"auto"} pt="8px">
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={3} alignItems="center">
              <Link to="/" style={{ color: "#717171" }}>
                <Img fluid={data.addrust_logo.childImageSharp.fluid} />
              </Link>
            </Grid>
            <Grid item xs={2}>
              {isOpen ? (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen(c => !c)}
                >
                  <Box style={{ width: "50%", maxWidth: "40px" }}>
                    <Img fluid={data.close.childImageSharp.fluid} />
                  </Box>
                </Button>
              ) : (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen(c => !c)}
                >
                  <Box style={{ width: "70%", maxWidth: "40px" }}>
                    <Img fluid={data.menuBar.childImageSharp.fluid} />
                  </Box>
                </Button>
              )}
            </Grid>
          </Grid>
          <Drawer
            anchor={"left"}
            open={isOpen}
            style={{ width: "100%" }}
            transitionDuration="1500"
          >
            <Box width="100vw" height="100vh" bgcolor="#242424">
              <Box width="90%" margin="auto" pt="32px">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={3}>
                    <Link
                      to="/"
                      style={{ color: "#717171" }}
                      onClick={() => setOpen(c => !c)}
                    >
                      <Img fluid={data.addrust_logo.childImageSharp.fluid} />
                    </Link>
                  </Grid>
                  <Grid item xs={2}>
                    {isOpen ? (
                      <Button
                        style={{
                          width: "100%",
                          maxWidth: "126px",
                          margin: "auto",
                        }}
                        onClick={() => setOpen(c => !c)}
                      >
                        <Box style={{ width: "50%", maxWidth: "20px" }}>
                          <Img fluid={data.close.childImageSharp.fluid} />
                        </Box>
                      </Button>
                    ) : (
                      <Button
                        style={{
                          width: "100%",
                          maxWidth: "126px",
                          margin: "auto",
                        }}
                        onClick={() => setOpen(c => !c)}
                      >
                        <Box style={{ width: "70%", maxWidth: "40px" }}>
                          <Img fluid={data.menuBar.childImageSharp.fluid} />
                        </Box>
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  height="80vh"
                >
                  <Box width="100%" textAlign="center">
                    <WrapTypo ls="5px" mt={sp_mtSize} fs="18px">
                      <Link
                        to="/"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(c => !c)}
                      >
                        TOP
                      </Link>
                    </WrapTypo>
                    <WrapTypo ls="5px" mt={sp_mtSize} fs="18px">
                      <Link
                        to="/#about"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(c => !c)}
                      >
                        ABOUT
                      </Link>
                    </WrapTypo>
                    <WrapTypo ls="5px" mt={sp_mtSize} fs="18px">
                      <Link
                        to="/#media"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(c => !c)}
                      >
                        MEDIA
                      </Link>
                    </WrapTypo>
                    <WrapTypo ls="5px" mt={sp_mtSize} fs="18px">
                      <Link
                        to="/recruit"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(c => !c)}
                      >
                        RECRUIT
                      </Link>
                    </WrapTypo>
                    <WrapTypo ls="5px" mt={sp_mtSize} fs="18px">
                      <Link
                        to="/#company"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(c => !c)}
                      >
                        COMPANY
                      </Link>
                    </WrapTypo>
                    <WrapTypo ls="5px" mt={sp_mtSize} fs="18px">
                      <Link
                        to="/contacts"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(c => !c)}
                      >
                        CONTACT
                      </Link>
                    </WrapTypo>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Hidden>
    </>
  )
}

export default Header
