import React from "react"
import { Helmet } from "react-helmet"

const Head = () => {
  return (
    <>
      <Helmet>
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap"
          rel="stylesheet"
        />
        <title>Addrust|公式WEBサイト</title>
        {/* ogp */}
        <meta
          name="description"
          content="Addict+Trust=Addrust 企業と学生をやみつきにし続ける存在に・・・. Vision. 「営業価値」と「雇用価値」の底上げこそ、弊社が掲げるビジョンです。"
        />
        <meta property="og:title" content="Addrust|公式サイト" />
        <meta
          property="og:description"
          content="Addict+Trust=Addrust 企業と学生をやみつきにし続ける存在に・・・. Vision. 「営業価値」と「雇用価値」の底上げこそ、弊社が掲げるビジョンです。"
        />
        <meta property="og:url" content="http://www.addrust.jp/" />
        <meta property="og:image" content="http://www.addrust.jp/ogp.png" />
        <meta name="twitter:card" content="Addrust公式サイトです" />
        <meta property="og:type" content="website" />
        <meta name="google" content="notranslate" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap"
          rel="stylesheet"
        />
      </Helmet>
    </>
  )
}

export default Head
