import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "../components/WrapTypo"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"

const RecruitStatement = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: { eq: "joinus.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      anj: file(relativePath: { eq: "catchcopy.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Box
        width="90%"
        maxWidth="1200px"
        m={"auto"}
        mt={[6, 10, 20, 20, 20]}
        position="relative"
        textAlign="left"
      >
        <Box
          position="absolute"
          top="-10%"
          left="0%"
          width="100%"
          data-aos="fade-right"
        >
          <Img
            fluid={data.title.childImageSharp.fluid}
            style={{ width: "60%", minWidth: "300px" }}
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <Box
            m={"auto"}
            display="inline-block"
            textAlign="left"
            mt={[4, 10, 18]}
          >
            <WrapTypo
              variant="h2"
              fs="40px"
              xsfs="20px"
              mdfs="30px"
              smfs="20px"
              xsta="center"
              lh="100%"
              ls="10%"
              color="#fff"
              fw="700"
            >
              「学生」で括られたくない君へ
            </WrapTypo>
            <Box mt={[2, 4, 8]} textAlign="left">
              <WrapTypo
                fs="24px"
                xsfs="12px"
                xsta="center"
                lh="350%"
                ls="5%"
                color="#fff"
                fw="700"
                style={{ margin: "auto", textAlign: "left" }}
                Notosans
              >
                　「学生なのにすごいね」
                <br />
                　そんな言葉がもどかしい。
                <br />
                　Addrustは、そう感じる人を求めている。
                <br />
                　社会というフィールドで、
                <br />
                　思い切り仕事をプレイできる。
                <br />
                　大きな責任も伴う。悔しいことだってある。
                <br />
                　それでもきっと、それらすべてに夢中になれる。
                <br />
                　想像以上のフィールドで、一緒にプレイしよう。
                <br />
              </WrapTypo>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default RecruitStatement
