import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "../components/WrapTypo"

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      tw_logo: file(relativePath: { eq: "tw_logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fb_logo: file(relativePath: { eq: "Facebook_Logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      in_logo: file(relativePath: { eq: "Instagram_logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      note_logo: file(relativePath: { eq: "note_logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Box width="100%" bgcolor="#4A4A4A" pt={6} pb={6} textAlign="center">
        <a href="/privacyPolicy">
          <WrapTypo color="#fff">Privacy Policy</WrapTypo>
        </a>
        <WrapTypo color="#fff" xsfs="14px" mt="32px">
          Copyright © Addrust Co., Ltd. All Rights Reserved.
        </WrapTypo>
      </Box>
    </>
  )
}

export default Footer
