import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Hidden } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "../components/WrapTypo"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"

const Profile = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: { eq: "media.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mediaImg: file(relativePath: { eq: "media_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      joby_logo: file(relativePath: { eq: "joby_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      media_cta: file(relativePath: { eq: "media_cta.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Box id="media"></Box>
      <Box
        width="100%"
        maxWidth="1200px"
        m={"auto"}
        mt="200px"
        maxWidth="1200px"
        position="relative"
      >
        <Hidden mdUp>
          <Box
            position="absolute"
            top="-10%"
            left="0%"
            width="100%"
            data-aos="fade-right"
            zIndex="10"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box
            position="absolute"
            top="-20%"
            left="0%"
            width="100%"
            data-aos="fade-right"
            zIndex="10"
          >
            <a name="Media">
              <Img
                fluid={data.title.childImageSharp.fluid}
                style={{ width: "60%", minWidth: "300px" }}
              />
            </a>
          </Box>
        </Hidden>
        <Box data-aos="fade-in" data-aos-duration="1000">
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <Img
                fluid={data.mediaImg.childImageSharp.fluid}
                style={{ width: "80%", maxWidth: "500px", margin: "auto" }}
              />
            </Grid>
            <Grid item xs={9} md={6}>
              <Img
                fluid={data.joby_logo.childImageSharp.fluid}
                style={{ width: "30%", maxWidth: "500px", margin: "auto" }}
              />
              <WrapTypo fs="24px" xsfs="18px" color="#fff" fw="700" mt="32px">
                就活生向けメディア ジョビー
              </WrapTypo>
              <WrapTypo color="#fff" fs="18px" mt="32px" textAlign="justify">
                様々な大学生に向けて、学生生活に役立つ情報から新しいことを始めたくなる刺激的な情報まで発信する大学生向けWEBメディア「Jobby」の運営をしています。
              </WrapTypo>
              <a href="http://jobhobby.jp/" target="_blank">
                <Img
                  fluid={data.media_cta.childImageSharp.fluid}
                  style={{
                    width: "100%",
                    maxWidth: "350px",
                    marginTop: "32px",
                    marignLeft: "auto",
                    marginRight: "0px",
                  }}
                />
              </a>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default Profile
