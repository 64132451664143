import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import { Grid } from "@material-ui/core"
import Box from "@material-ui/core/Box"

import MainHead from "../components/head"
import Header from "../components/Header_normal"
import Footer from "../components/footer"
import WrapTypo from "../components/WrapTypo"
import { ContactForm } from "../components/ContactForm"

const Contacts = data => {
  return (
    <>
      <MainHead />
      <Box width="100%" bgcolor="#242424">
        <Header />
        <Box height="80vh">
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Box textAlign="center" margin="0 0 20px">
                <WrapTypo color="#fff">
                  お問い合わせありがとうございました。
                  <br />
                  通常5営業日以内に返信させて頂きます。
                </WrapTypo>
              </Box>
            </Grid>
            <Grid item>
              <WrapTypo color="#fff">
                <Link to="/" style={{ color: "#fff" }}>
                  TOPへ
                </Link>
              </WrapTypo>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </>
  )
}

export default Contacts
