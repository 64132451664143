import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "../components/WrapTypo"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"
import { Hidden } from "@material-ui/core"

const RecruitStatement = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: { eq: "stories.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      readButton: file(relativePath: { eq: "read.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pic1: file(relativePath: { eq: "stories_pic1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pic2: file(relativePath: { eq: "stories_pic2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pic3: file(relativePath: { eq: "skil_pic31.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Box
        width="90%"
        maxWidth="1200px"
        m={"auto"}
        mt={[18, 10, 20, 20, 20]}
        position="relative"
        textAlign="left"
      >
        <Hidden mdUp>
          <Box
            position="absolute"
            top="-3%"
            left="0%"
            width="100%"
            data-aos="fade-right"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box
            position="absolute"
            top="-10%"
            left="0%"
            width="100%"
            data-aos="fade-right"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
        </Hidden>

        <Box data-aos="fade-in" data-aos-duration="1000">
          <Hidden smDown>
            <Grid container justify="space-between">
              <Grid imte xs={12} md={5} style={{ marginTop: "10%" }}>
                <Img fluid={data.pic1.childImageSharp.fluid} />
              </Grid>
              <Grid imte xs={12} md={6} style={{ marginTop: "10%" }}>
                <WrapTypo color="#fff" fw="700" mt="20%">
                  「こんな時代」だからこそ、見つめ直すべき価値があるもの。
                </WrapTypo>
                <a
                  href="https://www.wantedly.com/companies/company_7035095/post_articles/231776"
                  target="_blank"
                >
                  <Img
                    fluid={data.readButton.childImageSharp.fluid}
                    style={{
                      width: "40%",
                      maxWidth: "200px",
                      marginTop: "64px",
                      marginLeft: "auto",
                      marginRight: "0",
                    }}
                  />
                </a>
              </Grid>
              <Grid imte xs={12} md={5} style={{ marginTop: "10%" }}>
                <Img fluid={data.pic2.childImageSharp.fluid} style={{}} />
              </Grid>
              <Grid imte xs={12} md={6} style={{ marginTop: "10%" }}>
                <WrapTypo color="#fff" fw="700" mt="20%">
                  【創業10周年記念　社長にインタビュー】
                </WrapTypo>
                <WrapTypo color="#fff" mt={"16px"} fs="12px">
                  飯島三厳
                  〜「仲間が自慢したくなる会社」「苦しいことも、仲間がいれば『楽しい出来事の前触れ』」〜
                </WrapTypo>
                <a
                  href="https://www.wantedly.com/companies/company_7035095/post_articles/231776"
                  target="_blank"
                >
                  <Img
                    fluid={data.readButton.childImageSharp.fluid}
                    style={{
                      width: "40%",
                      maxWidth: "200px",
                      marginTop: "64px",
                      marginLeft: "auto",
                      marginRight: "0",
                    }}
                  />
                </a>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid container justify="space-between">
              <Grid imte xs={12} md={5} style={{ marginTop: "10%" }}>
                <Img fluid={data.pic1.childImageSharp.fluid} />
              </Grid>
              <Grid imte xs={12} md={6} style={{ marginTop: "10%" }}>
                <WrapTypo color="#fff" fw="700">
                  「こんな時代」だからこそ、見つめ直すべき価値があるもの。
                </WrapTypo>
                <a
                  href="https://www.wantedly.com/companies/company_7035095/post_articles/231776"
                  target="_blank"
                >
                  <Img
                    fluid={data.readButton.childImageSharp.fluid}
                    style={{
                      width: "40%",
                      maxWidth: "200px",
                      marginTop: "64px",
                      marginLeft: "auto",
                      marginRight: "0",
                    }}
                  />
                </a>
              </Grid>
              <Grid imte xs={12} md={5} style={{ marginTop: "10%" }}>
                <Img fluid={data.pic2.childImageSharp.fluid} style={{}} />
              </Grid>
              <Grid imte xs={12} md={6} style={{ marginTop: "10%" }}>
                <WrapTypo color="#fff" fw="700">
                  【創業10周年記念　社長にインタビュー】
                </WrapTypo>
                <WrapTypo color="#fff" mt={"16px"} fs="12px">
                  飯島三厳
                  〜「仲間が自慢したくなる会社」「苦しいことも、仲間がいれば『楽しい出来事の前触れ』」〜
                </WrapTypo>
                <a
                  href="https://www.wantedly.com/companies/company_7035095/post_articles/231776"
                  target="_blank"
                >
                  <Img
                    fluid={data.readButton.childImageSharp.fluid}
                    style={{
                      width: "40%",
                      maxWidth: "200px",
                      marginTop: "64px",
                      marginLeft: "auto",
                      marginRight: "0",
                    }}
                  />
                </a>
              </Grid>
            </Grid>
          </Hidden>
        </Box>
      </Box>
    </>
  )
}

export default RecruitStatement
