import React from "react"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

function Layout({ children }) {
  return <div>{children}</div>
}


export default Layout