import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Hidden } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "./WrapTypo"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"

const Recuit = () => {
  const data = useStaticQuery(graphql`
    query {
      title: file(relativePath: { eq: "recurit.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recurit_img: file(relativePath: { eq: "recruitImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      joby_logo: file(relativePath: { eq: "joby_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      media_cta: file(relativePath: { eq: "media_cta.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Box
        width="100%"
        maxWidth="1200px"
        m={"auto"}
        mt="200px"
        maxWidth="1200px"
        position="relative"
      >
        <Hidden mdUp>
          <Box
            position="absolute"
            top="-45%"
            left="0%"
            width="100%"
            data-aos="fade-right"
            zIndex="10"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box
            position="absolute"
            top="-25%"
            left="0%"
            width="100%"
            data-aos="fade-right"
            zIndex="10"
          >
            <Img
              fluid={data.title.childImageSharp.fluid}
              style={{ width: "60%", minWidth: "300px" }}
            />
          </Box>
        </Hidden>
      </Box>
      <Hidden mdUp>
        <Box
          data-aos="fade-in"
          data-aos-duration="1000"
          width="100%"
          marginTop="250px"
        >
          <a href="/recruit">
            <Img fluid={data.recurit_img.childImageSharp.fluid} />
          </a>
        </Box>
      </Hidden>
      <Hidden smDown>
        <Box
          data-aos="fade-in"
          data-aos-duration="1000"
          width="100%"
          marginTop="340px"
        >
          <a href="/recruit">
            <Img fluid={data.recurit_img.childImageSharp.fluid} />
          </a>
        </Box>
      </Hidden>
    </>
  )
}

export default Recuit
